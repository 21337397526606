import React from "react"
import "./cta.scss"
import { navigate } from "gatsby"
import FormsService from "../service/formsservice"

class CTA extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      submitted: false,
      submitting: false,
      emailError: false,
      formData: {},
    }
  }
  validateEmail = (email) => {
    if (/^[a-zA-Z0-9.'_%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email)) {
      this.setState({ emailError: false })
      return true
    } else {
      this.setState({ emailError: true })
      return false
    }
  }

  handleClickLaunch = (e) => {
    e.preventDefault()
    navigate("/launch")
  }

  handleChange = (e) => {
    const name = e.target.name
    const value = e.target.value
    this.setState((oldState) => {
      return {
        ...oldState,
        formData: {
          ...oldState.formData,
          [name]: value,
        },
      }
    })
  }

  handleSubmit = (e) => {
    e.preventDefault()
    if (this.validateEmail(this.state.formData.email)) {
      this.setState({ submitting: true })
      FormsService.getFreeReport(this.state.formData.email)
        .then((res) => {
          if (res) {
            this.setState({ submitted: true })
          }
        })
        .catch((error) => console.log("Error: ", error))
        .finally(() => {
          this.setState({ submitting: false })
        })
    }
  }

  render() {
    const { isFreeReport } = this.props
    const { submitted, submitting } = this.state

    return (
      <div className="cta">
        <div className="card-free-report">
          {isFreeReport ? (
            <>
              <div className="card-heading">
                You’re in charge, get started anytime​
              </div>
              <div className="card-desc">
                Get in touch, we’d love to show you why we’re the new standard
                in employee engagement. It takes just 10 mins.​
              </div>
              <form
                name="Report-Request"
                onSubmit={this.handleSubmit}
                disabled={submitted}
                id="free-report-form"
              >
                <div className="inputWrapper">
                  {!submitted && (
                    <input
                      type="text"
                      name="email"
                      id="free-report-input-email"
                      required
                      placeholder="Email address"
                      onChange={this.handleChange}
                    />
                  )}

                  <button
                    type="submit"
                    id="free-report-form-submit-button"
                    disabled={submitted || submitting}
                    className="text-white"
                    className={`${submitted ? "submittedButton" : ""}`}
                  >
                    {(submitted &&
                      "Got it! We'll be in touch as fast as we can.") ||
                      (submitting && "Submitting...") ||
                      "Get free report"}
                  </button>
                </div>
                {this.state.emailError && (
                  <div className="invalidEmail">Email is invalid</div>
                )}
              </form>
            </>
          ) : (
            <>
              <div className="card-heading">See results in one month​</div>
              <div className="card-desc">
                Survey your company with less hassle and start making changes
                faster than ever before​
              </div>
              <button
                id="cta-launch-btn"
                onClick={(e) => this.handleClickLaunch(e)}
              >
                Launch SHAPE
              </button>
            </>
          )}
        </div>
      </div>
    )
  }
}

export default CTA
