import React from "react"

import Col from "react-bootstrap/Col"
import Row from "react-bootstrap/Row"
import Container from "react-bootstrap/Container"

import Link from "gatsby-link"

import Layout from "../components/layout"
import SEO from "../components/seo"
import CTA from "../components/cta"

import "./features.scss"

const icons = {
  fourOrg: require("../images/feature/4OrgA2.png"),
  daap: require("../images/feature/daap.png"),
  ddt: require("../images/feature/ddt.png"),
  esp: require("../images/feature/esp.png"),
  personal: require("../images/feature/personal.png"),
  company: require("../images/feature/company.png"),
  hage: require("../images/feature/hage.png"),
  vob: require("../images/feature/vob.png"),
  aim: require("../images/feature/aim.png"),
  tpp: require("../images/feature/tpp.png"),
  yn: require("../images/feature/yn.png"),
  risk: require("../images/feature/risk.png"),
  bully: require("../images/feature/bully.png"),
  psc: require("../images/feature/psc.png"),
  ixe: require("../images/feature/ixe.png"),
  dass: require("../images/feature/dass.png"),
}

const Features = () => {
  return (
    <Layout>
      <SEO title="Features" />
      <div className="hero">
        <h1>Features</h1>
        <p>
          <strong>SHAPE</strong> enables easy understanding of complex employee
          data. We’ve developed features that guide your{" "}
          <strong>employees</strong>, <strong>managers</strong> and{" "}
          <strong>executives</strong> through a unique and illuminating data
          journey. Based on scientific insights, SHAPE details everything your
          company needs to measure your people performance.
        </p>
        <Link to="/contact" className="button-white text-teal">
          Get started
        </Link>
      </div>
      <div className="content">
        <section className="background large">
          <Container fluid>
            <Row>
              <Col sm={6}>
                <div className="image">
                  <img src={icons.fourOrg} alt="4 Organizational Areas" />
                </div>
              </Col>
              <Col sm={6} className="text">
                <h3>12 Explorers to explore</h3>
                <p>
                  Starting with the “big picture”, the overall SHAPE score
                  provides a single figure which represents how well the
                  conditions at an organisation support a high-performance
                  workforce. This score is subdivided into{" "}
                  <strong>
                    4 Organisational Areas – The Individual, The People, The
                    Facilities and The Company
                  </strong>
                  . These encompass our famous Explorers, the 12 critical
                  elements any company needs to exploit in order to claim
                  leadership status.
                </p>
              </Col>
            </Row>
          </Container>
        </section>
        <section className="background">
          <Container fluid>
            <Row>
              <Col sm={6}>
                <div className="small__item">
                  <img src={icons.daap} alt="The DAAP matrix" />
                  <h4>The DAAP model</h4>
                  <p>
                    Our simple mantra; “turn up and perform”, is encapsulated in
                    the DAAP model -{" "}
                    <strong>
                      the Desire and Ability to Attend and Perform.
                    </strong>{" "}
                    You may not visibly see DAAP in the reports, but it is
                    inherent in all aspects of SHAPE - all SHAPE Explorers, and
                    all the questions within them, impact one or more areas of
                    DAAP.
                  </p>
                </div>
              </Col>
              <Col sm={6}>
                <div className="small__item">
                  <img src={icons.hage} alt="Health Age" />
                  <h4>Health Age</h4>
                  <p>
                    SHAPE combines various health and lifestyle measures to
                    determine how an employee’s “Health Age” compares with their
                    actual age. It’s best not to have a workforce with an
                    average age of 40 that look more like 50 from a health
                    perspective! Health Age is the perfect solution for your
                    workplace health and wellbeing program.
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        {/* <section className="background">
          <Container fluid>
            <Row>
              <Col sm={6}>
                <div className="small__item">
                  <img src={icons.personal} alt="Personal Insights" />
                  <h4>Personal Insights</h4>
                  <p>
                    SHAPE combines data from across various Explorers in order
                    to provide greater insights into factors that impact
                    employee performance at the individual level
                  </p>
                </div>
              </Col>
              <Col sm={6}>
                <div className="small__item">
                  <img src={icons.company} alt="Company Insights" />
                  <h4>Company Insights</h4>
                  <p>
                    Similar to Individual Insights, but focused at company level
                    analytics. Examples include: What are our worst health
                    risks? How is our “Life balance”? Are we hiring the best
                    talent? How valued do our employees feel? How safe do our
                    employees feel? Chronic health conditions and presenteeism
                    etc.
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </section> */}

        {/*
          =====
          ESP
          =====
        */}
        <section className="background large">
          <Container fluid>
            <Row>
              <Col sm={6} className="image">
                <img src={icons.dass} alt="DASS" />
              </Col>
              <Col sm={6} className="text">
                <h3>Psychometrics</h3>
                <p>
                  Supporting the psycho-emotional wellbeing of employees is the
                  greatest challenge facing employers over the coming decade.
                  Mental health is already the leading preventable risk and is
                  growing. SHAPE combines the globally validated,{" "}
                  <strong>Depression, Anxiety and Stress Scales</strong> (
                  <strong>DASS</strong>) with an appraisal of the emotional,
                  cognitive, behavioural and physical consequences of poor
                  psychological health. This allows a precise, scientifically
                  valid assessment of the mental health profile from individual
                  to overall company. In addition, the "sources of stress"
                  appraisal identifies the root causes of the problem, providing
                  invaluable data to fine tune interventions and deliver optimal
                  results.
                </p>
              </Col>
            </Row>
          </Container>
        </section>

        <section className="background">
          <Container fluid>
            <Row>
              <Col sm={6}>
                <div className="small__item">
                  <img
                    src={icons.psc}
                    alt="Psychological Safety Climate (PSC-22)"
                  />
                  <h4>Psychological Safety Climate (PSC-22)</h4>
                  <p>
                    The PSC-22 takes over twenty results across 8 of the 12
                    Explorers and combines them into a single measure to
                    indicate{" "}
                    <strong>
                      whether an employee’s psycho-emotional state is likely to
                      be impacted, positively or negatively, by various
                      work-related factors.
                    </strong>{" "}
                    The PSC-22 therefore reflects the degree to which the
                    workplace "strains" the individual psychological coping
                    resources of employees.
                  </p>
                </div>
              </Col>
              <Col sm={6}>
                <div className="small__item">
                  <img
                    src={icons.ixe}
                    alt="The Individual in the Environment (IxE)"
                  />
                  <h4>The Individual in the Environment (IxE)</h4>
                  <p>
                    Whereas the PSC-22 measures the degree to which the
                    workplace is positive, benign or toxic in terms of
                    psychological safety, IxE combines this with a{" "}
                    <strong>measure of individual susceptibility.</strong>{" "}
                    Employees are on a continuum from resilient to susceptible.
                    Clearly, the{" "}
                    <strong>
                      highest risk exists when susceptible employees are in a
                      hostile environment
                    </strong>{" "}
                    – the epicentre for stress claims.
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        <section className="background">
          <Container fluid>
            <Row>
              <Col sm={12}>
                <div className="small__item">
                  <img src={icons.aim} alt="Autonomy in Isolation Model" />
                  <h4>Autonomy in Isolation Model</h4>
                  <p className="width-constraint">
                    The way we work is changing. As more companies adopt a work
                    from home approach, there is a need to look towards the
                    future.{" "}
                    <strong>The Autonomy in Isolation Model (AIM)</strong>{" "}
                    evaluates the ability of employees to remain{" "}
                    <strong>productive at home</strong> and how well they deal
                    with <strong>physical separation</strong> from the office
                    and their colleagues.
                  </p>
                </div>
              </Col>

              {/* <Col sm={6}>
                <div className="small__item">
                  <img src={icons.bully} alt="Bullying & Harassment Profile" />
                  <h4>Bullying & Harassment Profile</h4>
                  <p>
                    A detailed review looking at all aspects of{" "}
                    <strong>
                      bullying and harassment (verbal, physical, sexual,
                      discriminatory and job related).
                    </strong>{" "}
                    Drills into the details of{" "}
                    <strong>
                      victim/perpetrator relationship (subordinate, peer,
                      superior, gender), mode (F2F, online, in absentia),
                      actions taken, reason(s) for inaction and outcome/impact.
                    </strong>{" "}
                    This provides an extremely comprehensive portrayal of what
                    is transpiring in relation to bullying and harassment within
                    the organisation.
                  </p>
                </div>
              </Col> */}
            </Row>
          </Container>
        </section>

        <section className="background large">
          <Container fluid>
            <Row>
              <Col sm={6} className="image">
                <img src={icons.yn} alt="Your Network" />
              </Col>
              <Col sm={6} className="text">
                <h3>Your Network</h3>
                <p>
                  Build your <strong>organisational structure</strong> from the
                  ground up, one employee at a time. Who do you report to and
                  who reports to you? Using <Link to="/ai">AI</Link>, we chart
                  the web of connections and provide a precise view of your
                  organisation, both from a <strong>reporting</strong> and an{" "}
                  <strong>intervention</strong> perspective. Know exactly where
                  to make a difference.
                </p>
              </Col>
            </Row>
          </Container>
        </section>

        <section className="background">
          <Container fluid>
            <Row>
              <Col sm={6}>
                <div id="vob" className="small__item">
                  <img src={icons.vob} alt="Value of Benefit" />
                  <h4>Return on Investment</h4>
                  <p>
                    When companies improve their SHAPE Score, improvements in
                    four critical areas occur:{" "}
                    <strong>
                      reduced absenteeism, increased productivity, reduced staff
                      turnover, and reduced illness/injury/accident/LTIFRs (Lost
                      Time Injury Frequency Rate)
                    </strong>
                    . These can be expressed in equivalent hours of additional
                    work, or the financial value of those hours based on the
                    wage of the employee; the ROI.
                  </p>
                </div>
              </Col>
              <Col sm={6}>
                <div className="small__item">
                  <img src={icons.ddt} alt="DDT" />
                  <h4>Demographic Data Tables</h4>
                  <p>
                    These allow executives and managers to view company data
                    across all Explorers based on primary demographics such as{" "}
                    <strong>
                      age, gender, site, state, job level or department/division
                    </strong>
                    . Such information is extremely helpful in identification of
                    intervention targets to improve SHAPE metrics.
                  </p>
                </div>
              </Col>
              {/* <Col sm={6}>
                <div className="small__item">
                  <img src={icons.risk} alt="Risk Report" />
                  <h4>Risk Report</h4>
                  <p>
                    The risk Report frames selected SHAPE data in the context of
                    business risk and provides executives with information
                    regarding the most critical internal factors facing the
                    company and where they can coordinate their intervention
                    efforts. Business risks are analysed across{" "}
                    <strong>10 categories</strong> each of which may represent
                    an intervention or risk mitigation opportunity;{" "}
                    <strong>
                      Productivity risk, Mental Health risk, Physical Health
                      risk, Absence risk, Retention risk, Burnout risk, Bullying
                      & Harassment, Financial risk and Business risk.
                    </strong>
                  </p>
                </div>
              </Col> */}
            </Row>
          </Container>
        </section>

        {/* <section className="background">
          <Container fluid>
            <Row>
              <Col sm={6}>
                <div className="small__item">
                  <img src={icons.tpp} alt="Team Performance Pyramid (TPP)" />
                  <h4>Team Performance Pyramid (TPP)</h4>
                  <p>
                    TPPs can provide clear visualisation of{" "}
                    <strong>“hot spots”</strong> across any of the 12 Explorers.{" "}
                    The <strong>“view”</strong> provided to the team manager is
                    from their team down, and the best and worst of the teams
                    are easily identifiable. With TPP you have the power to{" "}
                    <strong>
                      identify and manage the critical factors that affect your
                      workforce.
                    </strong>
                  </p>
                </div>
              </Col>
              <Col sm={12}>
                <div className="small__item">
                  <img src={icons.ddt} alt="DDT" />
                  <h4>Demographic Data Tables</h4>
                  <p className="width-constraint">
                    These allow executives and managers to view company data
                    across all Explorers based on primary demographics such as{" "}
                    <strong>
                      age, gender, site, state, job level or department/division
                    </strong>
                    . Such information is extremely helpful in identification of
                    intervention targets to improve SHAPE metrics.
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </section> */}

        {/* <section className="background large">
          <Container fluid>
            <Row>
              <Col sm={6} className="image">
                <img src={icons.esp} alt="ESP" />
              </Col>
              <Col sm={6} className="text">
                <h3>Employee SHAPE Profile</h3>
                <p>
                  Employee SHAPE Profile (ESP) introduces{" "}
                  <strong>16 exciting new “personas"</strong>. Each persona
                  represents interesting and fun characteristics about employees
                  based on their responses to the SHAPE Survey. The ESP provides
                  engaging insights that help employees focus their efforts to
                  improve and support their colleagues. An employee can share
                  the ESP, which invites others to get their own SHAPE, get
                  their ESP and continue the learning and improving.
                </p>
              </Col>
            </Row>
          </Container>
        </section> */}
      </div>
    </Layout>
  )
}

export default Features
